// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

export const mergeDeep = (
  target: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  source: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const output = { ...target };

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key: string) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};
