import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// Layouts
const LayoutSignedOut = () => import("@/views/layouts/LayoutSignedOut.vue");
const LayoutSignedIn = () => import("@/views/layouts/LayoutSignedIn.vue");
// Auth
const SignIn = () => import("@/views/auth/SignIn.vue");
const SignOut = () => import("@/views/auth/SignOut.vue");
const PasswordReminder = () => import("@/views/auth/PasswordReminder.vue");
const PasswordReset = () => import("@/views/auth/PasswordReset.vue");
const AcceptInvite = () => import("@/views/auth/AcceptInvite.vue");
const ActivateAccount = () => import("@/views/auth/ActivateAccount.vue");
// Pages
const Dashboard = () => import("@/views/dashboard/Dashboard.vue");
const PassThrough = () => import("@/views/PassThrough.vue");
const ProductsList = () => import("@/views/products/ProductsList.vue");
const ProductDetails = () => import("@/views/products/ProductDetails.vue");
const ProductOverviewTab = () =>
  import("@/views/products/ProductOverviewTab.vue");
const ProductHistoryTab = () =>
  import("@/views/products/ProductHistoryTab.vue");
const ProductNotesTab = () => import("@/views/products/ProductNotesTab.vue");
const ProductActivityTab = () =>
  import("@/views/products/ProductActivityTab.vue");
const WatchList = () => import("@/views/watchlist/WatchList.vue");
const SellersList = () => import("@/views/sellers/SellersList.vue");
const SellerDetails = () => import("@/views/sellers/SellerDetails.vue");
const SellerOverview = () => import("@/views/sellers/tabs/SellerOverview.vue");
const SellerProducts = () => import("@/views/sellers/tabs/SellerProducts.vue");
const SellerNotes = () => import("@/views/sellers/tabs/SellerNotes.vue");
const SellerMetadata = () => import("@/views/sellers/tabs/SellerMetadata.vue");
const SellerCustomFields = () =>
  import("@/views/sellers/tabs/SellerCustomFields.vue");
const SellerActivityTab = () =>
  import("@/views/sellers/tabs/SellerActivityTab.vue");
// Activity
const ActivityList = () => import("@/views/activities/ActivityList.vue");
// Stores
import { AuthStore } from "@/store/modules/Auth";
import {
  filtersToQueryString,
  generateRandomString,
} from "@pasabi/ui-patterns";
import i18n from "../i18n";
import { ProductDetailsStore } from "../store/modules/ProductDetails";
import { SellerDetailsStore } from "../store/modules/SellerDetails";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/auth",
    component: LayoutSignedOut,
    meta: {
      auth: false,
    },
    children: [
      {
        path: "sign-in",
        name: "signin",
        component: SignIn,
        meta: {
          auth: false,
        },
      },
      {
        path: "sign-out",
        name: "signout",
        component: SignOut,
        meta: {
          auth: false,
        },
      },
      {
        path: "reminder",
        name: "reminder",
        component: PasswordReminder,
        meta: {
          auth: false,
        },
      },
      {
        path: "reset",
        name: "reset",
        component: PasswordReset,
        meta: {
          auth: false,
        },
      },
      {
        path: "accept",
        name: "accept",
        component: AcceptInvite,
        meta: {
          auth: false,
        },
      },
      {
        path: "activate",
        name: "activateAccount",
        component: ActivateAccount,
        meta: {
          auth: false,
        },
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    component: LayoutSignedIn,
    children: [
      {
        path: "",
        component: PassThrough,
      },
      {
        name: "WorkspaceHome",
        path: "w/:workspaceSlug",
        redirect: { name: "w:Dashboard" },
        component: PassThrough,
        meta: {
          workspaceContext: true,
        },
        children: [
          {
            path: "",
            redirect: "w:Dashboard",
            meta: {
              breadcrumb: "Dashboard",
            },
          },
          {
            path: "dashboard",
            name: "w:Dashboard",
            component: Dashboard,
            meta: {
              auth: true,
              breadcrumb: "Dashboard",
            },
          },
          {
            path: "products",
            name: "Products",
            component: PassThrough,
            meta: {
              breadcrumb: i18n.t("products.list.title"),
            },
            redirect: {
              name: "ProductsList",
              query: {
                filters: filtersToQueryString({
                  condition: "and",
                  values: [
                    {
                      id: generateRandomString(),
                      field: "recent",
                      operator: "in",
                      value: ["unseen", "updated"],
                      group: null,
                    },
                  ],
                }),
              },
            },
            children: [
              {
                path: "",
                name: "ProductsList",
                component: ProductsList,
                meta: {
                  auth: true,
                },
              },
              {
                path: ":id",
                name: "ProductDetails",
                component: ProductDetails,
                redirect: {
                  name: "ProductOverviewTab",
                },
                meta: {
                  auth: true,
                  breadcrumb: function breadcrumb() {
                    return ProductDetailsStore.productName(
                      this.$route.params.id,
                    );
                  },
                },
                children: [
                  {
                    path: "",
                    name: "ProductOverviewTab",
                    component: ProductOverviewTab,
                    meta: {
                      auth: true,
                      breadcrumb: "Overview",
                    },
                  },
                  {
                    path: "history",
                    name: "ProductHistoryTab",
                    component: ProductHistoryTab,
                    meta: {
                      auth: true,
                      breadcrumb: "History",
                    },
                  },
                  {
                    path: "notes",
                    name: "ProductNotesTab",
                    component: ProductNotesTab,
                    meta: {
                      auth: true,
                      breadcrumb: "Notes",
                    },
                  },
                  {
                    path: "activity",
                    name: "ProductActivityTab",
                    component: ProductActivityTab,
                    meta: {
                      auth: true,
                      breadcrumb: "Activity",
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "sellers",
            name: "Sellers",
            component: PassThrough,
            redirect: {
              name: "SellersList",
            },
            meta: {
              breadcrumb: i18n.t("sellers.list.title"),
            },
            children: [
              {
                path: "",
                name: "SellersList",
                component: SellersList,
                meta: {
                  auth: true,
                },
              },
              {
                path: ":id",
                name: "SellerDetails",
                component: SellerDetails,
                redirect: {
                  name: "SellerOverviewTab",
                },
                meta: {
                  auth: true,
                  breadcrumb: function breadcrumb() {
                    return SellerDetailsStore.sellerName(this.$route.params.id);
                  },
                },
                children: [
                  {
                    path: "",
                    name: "SellerOverviewTab",
                    component: SellerOverview,
                    meta: {
                      auth: true,
                      breadcrumb: "Overview",
                    },
                  },
                  {
                    path: "products",
                    name: "SellerProductsTab",
                    component: SellerProducts,
                    meta: {
                      auth: true,
                      breadcrumb: "Products",
                    },
                  },
                  {
                    path: "comments",
                    name: "SellerNotesTab",
                    component: SellerNotes,
                    meta: {
                      auth: true,
                      breadcrumb: "Comments",
                    },
                  },
                  {
                    path: "fields",
                    name: "SellerCustomerFieldsTab",
                    component: SellerCustomFields,
                    meta: {
                      auth: true,
                      breadcrumb: "Fields",
                    },
                  },
                  {
                    path: "metadata",
                    name: "SellerMetadataTab",
                    component: SellerMetadata,
                    meta: {
                      auth: true,
                      breadcrumb: "Metadata",
                    },
                  },
                  {
                    path: "history",
                    name: "SellerActivityTab",
                    component: SellerActivityTab,
                    meta: {
                      auth: true,
                      breadcrumb: "Activity",
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "watchlist",
            name: "Watchlist",
            component: PassThrough,
            redirect: {
              name: "WatchList",
            },
            meta: {
              breadcrumb: i18n.t("watchlist.list.title"),
            },
            children: [
              {
                path: "",
                name: "Watchlist",
                component: WatchList,
                meta: {
                  auth: true,
                },
              },
            ],
          },
          {
            path: "activity",
            name: "Activity",
            component: ActivityList,
          },
        ],
      },
      {
        name: "GroupHome",
        path: "g/:groupId",
        redirect: { name: "g:Dashboard" },
        component: PassThrough,
        meta: {
          groupContext: true,
        },
        children: [
          {
            path: "",
            redirect: "g:Dashboard",
            meta: {
              breadcrumb: "Dashboard",
            },
          },
          {
            path: "dashboard",
            name: "g:Dashboard",
            component: Dashboard,
            meta: {
              auth: true,
              breadcrumb: "Dashboard",
            },
          },
        ],
      },
    ],
  },
  { path: "*", redirect: { name: "Home" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log('Router, before each', to.meta?.auth, from.meta?.auth);
  if (to.matched.some((route) => route.meta?.auth === true)) {
    if (AuthStore.authenticated()) {
      next();
    } else {
      next({ name: "signin", query: { redirect: to.fullPath } });
    }
  } else {
    next();
  }
});

export default router;
