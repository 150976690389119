import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { AuthStore } from "@/store/modules/Auth";
import { $events } from "@pasabi/ui-components";
import router from "@/router";
import { WorkspacesStore } from "../store/modules/Workspaces";
import { GroupsStore } from "../store/modules/Groups";

export interface ServerResponse<T> {
  code: number;
  message: string;
  payload: T;
  error?: string;
}

// Get the env based settings
const API_URL = process.env.VUE_APP_API_URL || "";
const API_PATH = process.env.VUE_APP_API_PATH || "/api/v4";
const APP_TYPE = process.env.VUE_APP_TYPE || "pwa";
const APP_VERSION = process.env.VUE_APP_VERSION || "0.0.1";

/**
 * Default axios instance
 */
const axiosParams = {
  baseURL: API_URL + API_PATH,
  headers: {
    "X-Pasabi-App": `${APP_TYPE} ${APP_VERSION}`,
  },
};

const http = axios.create(axiosParams);
const unauthenticatedHttp = axios.create(axiosParams);

// Request interceptors

/**
 * Default request interceptor to emit an api:start event
 * @param config axios config (we don't do anything with it)
 */
const unauthenticatedRequestInterceptor = (
  config: AxiosRequestConfig,
): AxiosRequestConfig => {
  $events.$emit("api:start", { type: "default" });
  // console.info(`[api request] ${JSON.stringify(config)}`);
  return config;
};

/**
 * Default request interceptor to emit an api:start event
 * @param config axios config (we don't do anything with it)
 */
const authenticatedRequestInterceptor = (config: AxiosRequestConfig) => {
  $events.$emit("api:start", { type: "auth" });
  /* eslint-disable no-param-reassign */
  if (!config.headers) {
    config.headers = {};
  }
  config.headers["Authorization"] = `Bearer ${AuthStore.accessToken()}`;

  if (WorkspacesStore.currentWorkspaceId || GroupsStore.currentGroupId) {
    config.headers["Context"] = WorkspacesStore.currentWorkspaceId
      ? `w:${WorkspacesStore.currentWorkspaceId}`
      : GroupsStore.currentGroupId
        ? `g:${GroupsStore.currentGroupId}`
        : "";
  }

  /* eslint-enable no-param-reassign */
  // TODO: Check e date and see if we need to perform a token refresh first
  return config;
};

// Request Error interceptors

/**
 * Default error interceptor to emit an api:end event
 * @param error axios config (we don't do anything with it)
 */
const requestErrorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[api error] [${JSON.stringify(error)}]`);
  $events.$emit("api:end", { type: "default" });
  return Promise.reject(error);
};

// Response Interceptors

const responseInterceptor = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resp: AxiosResponse<ServerResponse<any>>,
): AxiosResponse => {
  $events.$emit("api:end");
  return resp;
};

// Response Error Interceptors

const unauthenticatedResponseErrorInterceptor = (
  error: AxiosError,
): Promise<AxiosError> => {
  console.error(`[api error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const authenticatedResponseErrorInterceptor = (error: AxiosError) => {
  if (error.response?.status === 401 || error.response?.status === 403) {
    if (router.currentRoute.name !== "signin") {
      AuthStore.logout().then(() => {
        router.push({ name: "signin" }).then();
      });
    }
  }
  return Promise.reject(error);
};

// Set the default interceptors
http.interceptors.request.use(
  authenticatedRequestInterceptor,
  requestErrorInterceptor,
);
http.interceptors.response.use(
  responseInterceptor,
  authenticatedResponseErrorInterceptor,
);

unauthenticatedHttp.interceptors.request.use(
  unauthenticatedRequestInterceptor,
  requestErrorInterceptor,
);
unauthenticatedHttp.interceptors.response.use(
  responseInterceptor,
  unauthenticatedResponseErrorInterceptor,
);

export const $api = http;
export const $unauthenticatedApi = unauthenticatedHttp;
